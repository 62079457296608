import React from 'react';

import styles from "./Contact.module.css"
import { FaDiscord } from "react-icons/fa";


const Contact = () => {
  return (
    <div className={styles.contactPage}>
      <h1 className={styles.title}>Contact Us</h1>

      <div className={styles.section}>
        <h3>Instant Support</h3>
        <p>
          For real-time support, join our Discord channel by clicking the link below:
        </p>
        <a 
          href="https://discord.com/invite/hvBmsd68Jh" 
          target="_blank" 
          rel="noopener noreferrer"
          className={styles.discordLink}
        >
         <div className={styles.iconContainer}><FaDiscord /></div> <div>Join Our Discord</div>
        </a>
      </div>

      <div className={styles.section}>
        <h3>Business Inquiries</h3>
        <p>
          For advertisement, sponsorship, suggestions, or complaints, feel free to reach out via email:
        </p>
        <div>
          albitrade.info@gmail.com 
        </div>
      </div>
    </div>
  );
};

export default Contact;
