import React from 'react';
import styles from './NotFound.module.css'; // Module CSS'yi import ediyoruz
import { Link } from 'react-router-dom'; // Kullanıcıları ana sayfaya yönlendirmek için Link bileşeni

const NotFoundPage = () => {
  return (
    <div className={styles.notFoundPage}>
      <h1 className={styles.errorCode}>404</h1>
      <p className={styles.message}>Oops! The page you're looking for does not exist.</p>
      <Link to="/dashboard" className={styles.homeLink}>Go to Dashboard</Link>
    </div>
  );
};

export default NotFoundPage;
