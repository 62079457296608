import React from 'react';
import styles from './HowCanIdeposit.module.css';

const HowCanIDeposit = () => {
  const items = [
    {
      title: "Go To Your Deposit Island",
      description: "If there is a deposit island assigned to you in the system, you need to teleport to that island. If you don't have an island yet just wait and the authorities will give you one.",
      imageUrl: require("../../assets/depositimages/Ekran görüntüsü 2024-09-20 155503.webp"),
      imageAlt: "Başlık 1 Görsel",
    },
    {
      title: "Put Item In Deposit Chest",
      description: "You can deposit the items you want to deposit into the deposit box by selecting the deposit box above. Your deposits will be automatically detected from the chest logs. This process may take up to a day. We are sorry for the delay.",
      imageUrl: require("../../assets/depositimages/Ekran görüntüsü 2024-09-20 155606.webp"),
      imageAlt: "Başlık 2 Görsel",
    }
  ];

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>How Can I Deposit</h1>
      {items.map((item, index) => (
        <div key={index} className={styles.item}>
          <h2 className={styles.itemTitle}>{item.title}</h2>
          <p className={styles.itemDescription}>{item.description}</p>
          <a href={item.imageUrl} target="_blank" rel="noopener noreferrer">
            <img
              src={item.imageUrl}
              alt={item.imageAlt}
              className={styles.itemImage}
            />
          </a>
        </div>
      ))}
    </div>
  );
};

export default HowCanIDeposit;
