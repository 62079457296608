import React, { useEffect, useState } from "react";

import styles from "./GuildList.module.css";
import { FaDiscord } from "react-icons/fa";
import { GiCheckedShield } from "react-icons/gi";
import axios from "axios";

const GuildList = ({isMobile}) => {
  const [guilds, setGuilds] = useState([]);

  const getGuilds = async () => {
    try {
      const { data } =await axios.get("https://albitrade.com/api/guilds/getguilds");
      setGuilds(data?.guilds);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    getGuilds()
  },[])

  return (
    <div className={styles.guildListPage}>
      <div className={styles.header}>
        <h1 className={styles.title}>Guild List</h1>
        <a
          href="https://discord.com/invite/hvBmsd68Jh"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.discordButton}
        >
          <div className={styles.dcicon}>
            <FaDiscord />
          </div>
          Add Your Guild
        </a>
      </div>

      <div className={isMobile ? styles.guildListMobile : styles.guildList}>
        {guilds.map((guild) => (
          <div key={guild._id} className={styles.guildCard}>
            <ul className={styles.guildDescription}>
              <img
                src={guild.imageurl}
                alt={guild.name}
                className={styles.guildImage}
              />
              <h2 className={styles.guildName}>{guild.name}</h2>
              {guild.features.map((desc, index) => (
                <li key={index}>
                  <span className={styles.desDot}>
                    <GiCheckedShield />
                  </span>
                  {desc}
                </li>
              ))}
            </ul>
            <p className={styles.contact}>Contact: {guild.user}</p>
            <a
              href={guild.discordUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.discordButton}
            >
              <div className={styles.dcicon}>
                <FaDiscord />
              </div>
              discord
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GuildList;
