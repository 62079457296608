import React, { useState } from 'react';

import styles from "./Affiliate.module.css"
import { useAtom } from 'jotai';
import { userInfoAtom } from '../../../store/jotai';
import axios from 'axios';

const AffiliatePage = ({showMessage,getUserInfo}) => {
  const [referredInviteCode, setReferredInviteCode] = useState('');
  const [userInfo,setUserInfo]=useAtom(userInfoAtom)

  const handleReferredInviteCodeChange = (event) => {
    setReferredInviteCode(event.target.value);
  };

  const handleReferredInviteCodeSubmit = async(e) => {
    try {
      e.preventDefault()
      const token = localStorage.getItem("session")
      const {data} = await axios.post("https://albitrade.com/api/users/setAffiliate",{
        affiliateCode:referredInviteCode,
        token
      })
      showMessage(data?.message,"success")
      await getUserInfo()
    } catch (error) {
      setReferredInviteCode("")
      showMessage(error?.response?.data?.error,"error")
    }
  };

  return (
    <div className={styles.affiliatePage}>
      <h1 className={styles.title}>Affiliate Program</h1>

      <div className={styles.inviteCodeSection}>
        <h3>Your Invite Code</h3>
        <p className={styles.inviteCode}>{userInfo.affiliateCode}</p>
        <p>Share your code and earn 1% from your friends deposits!</p>
      </div>

      {!userInfo.affiliateCodeIsUsed && (
        <div className={styles.referredInviteCodeSection}>
          <h3>Enter an Invite Code</h3>
          <form onSubmit={handleReferredInviteCodeSubmit} className={styles.inviteCodeForm}>
            <input
              type="text"
              value={referredInviteCode}
              onChange={handleReferredInviteCodeChange}
              placeholder="Enter someone else's invite code"
              className={styles.inviteInput}
            />
            <button type="submit" className={styles.submitButton}>Submit Code</button>
          </form>
        </div>
      )}
      {userInfo.affiliateCodeIsUsed && (
        <div className={styles.referredInviteCodeSection}>
          <h3 style={{color:"orange"}}>The user whose code you used: {userInfo.affiliateCodeIsUsed}</h3>
        </div>
      )}
      <div className={styles.referredUsersSection}>
        <h3>Your Referred Earning</h3>
          <ul className={styles.referredUsersList}>
              <li>
                You Earned total: {userInfo?.affiliateEarnings} (1% of deposit)
              </li>
          </ul>
      </div>
    </div>
  );
};

export default AffiliatePage;
