import React, { useState } from 'react';
import styles from './SettingsPage.module.css'; // Module CSS'yi import ediyoruz
import axios from 'axios';

const SettingsPage = ({showMessage}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [language, setLanguage] = useState('English');
  const [passwordError, setPasswordError] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handlePasswordSave = async() => {
    if (password == confirmPassword) {
      try {
        
        const token = localStorage.getItem("session")
        const {data}=await axios.post("https://albitrade.com/api/auth/resetPassword",{
          token,
          newPassword:password
        })
        showMessage(data?.message,"success")
        setPassword('');
        setConfirmPassword('');
      } catch (error) {
        showMessage("Error","error") 
      }
    } else {
      setPasswordError('Passwords do not match.');
    }
  };

  const handleLanguageSave = () => {
    alert('Language preference saved!');
  };

  return (
    <div className={styles.settingsPage}>
      <h1 className={styles.title}>Settings</h1>
      
      <div className={styles.settingSection}>
        <h2 className={styles.sectionTitle}>Change Password</h2>
        <input 
          type="password" 
          value={password} 
          onChange={handlePasswordChange} 
          placeholder="Enter new password" 
          className={styles.inputField}
        />
        <input 
          type="password" 
          value={confirmPassword} 
          onChange={handleConfirmPasswordChange} 
          placeholder="Confirm new password" 
          className={styles.inputField}
        />
        {passwordError && <p className={styles.error}>{passwordError}</p>}
        <button onClick={handlePasswordSave} className={styles.saveButton}>
          Save Password
        </button>
      </div>

      <div className={styles.settingSection}>
        <h2 className={styles.sectionTitle}>Language</h2>
        <select 
          value={language} 
          onChange={handleLanguageChange} 
          className={styles.selectField}
        >
          <option value="English">English</option>
          {/* Gelecekte başka diller eklenebilir */}
        </select>
        <button onClick={handleLanguageSave} className={styles.saveButton}>
          Save Language
        </button>
      </div>
    </div>
  );
};

export default SettingsPage;
