import React, { useEffect, useState } from "react";
import styles from "./HomePage.module.css";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive'
import axios from "axios";
import CookieConsent from "react-cookie-consent";

const HomePage = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
  const [stats,setStats]=useState({})

  const getData = async ()=>{
    try {
      const {data}=await axios.get("https://albitrade.com/api/pages/getwebMain")
      setStats(data)      
    } catch (error) {
      console.log("bir hata oluştu")
    }
  }

  useEffect(()=>{
    getData()
  },[])
  return (
    <div className={styles.container}>
        <Helmet>
        <title>AlbiTrade</title>
      </Helmet>
      <CookieConsent
      enableDeclineButton
        location="bottom"
        buttonText="Agree"
        declineButtonText="Decline"
        cookieName="myAppCookieConsent"
        style={{ background: "#242424", color: "#FFF" }}
        buttonStyle={{ color: "black", fontSize: "14px" }}
        declineButtonStyle={{ fontSize: "14px",color:"white" }}
        expires={150}
      >
        We use cookies to personalize content and ads, to provide social media features, and to analyze our traffic.
      </CookieConsent>
      <div className={styles.headerContainer}>
        <div className={isMobile ?styles.headerLeftMobile : styles.headerLeft}>
          <div className={isMobile ? styles.mobileTitle : styles.title}>
            Albi<span style={{ color: "#FF5F1F" }}>Trade</span>
          </div>
        </div>
        <div className={isMobile ?styles.headerRightMobile : styles.headerRight}>
          <Link to={"/contact"} style={{textDecoration:"none"}}>
            <div className={styles.contactBtn}>Contact</div>
          </Link>
          <Link to={"/login"}>
            <Button className={isMobile ?styles.mobileRegisterBtn : styles.registerBtn}>Login</Button>
          </Link>
          <Link to={"/register"}>
            <Button className={isMobile ?styles.mobileRegisterBtn : styles.registerBtn}>Register</Button>
          </Link>
        </div>
      </div>
      <div className={isMobile ?styles.contentContainerMobile : styles.contentContainer}>
        <div className={isMobile ?styles.leftContentMobile : styles.leftContent}>
          <div className={isMobile ? styles.text1Mobile : styles.text1}>Welcome To</div>
          <h1 className={isMobile ? styles.text1Mobile : styles.text1}>Albi<span style={{ color: "#FF5F1F" }}>Trade</span></h1>
          <div className={isMobile ? styles.text2Mobile : styles.text2}>Sell all your items instantly and buy a new item. completely free and fast...</div>
        </div>
        <div className={isMobile ?styles.rightContentMobile : styles.rightContent}>
        <div className={styles.statBox}>
              <h4>Total Market Value</h4>
              <p>{stats ? (stats?.totalPrice || 0).toLocaleString("tr-TR") : "10000000"}</p>
          </div>
        <div className={styles.statBox}>
              <h4>Total Users</h4>
              <p>{stats ? stats?.userLenght<250 ? (250+stats?.userLenght) : stats?.userLenght : 252}</p>
          </div>
        <div className={styles.statBox}>
              <h4>Total Market Item</h4>
              <p>{stats ? stats?.itemsLenght<50 ? (50+stats?.itemsLenght) : stats?.itemsLenght : 54}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
