import React, { useState } from "react";

import styles from "./Header.module.css";
import { BiSolidCoinStack } from "react-icons/bi";
import { GrUpdate } from "react-icons/gr";
import { FaUser } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import { useAtom } from "jotai";
import { userInfoAtom } from "../../store/jotai";

const Header = ({isMobile,setShowMenu,getUserInfo}) => {
  const [userInfo, setUserInfo]=useAtom(userInfoAtom)
  const handleOpen = () => {
    setShowMenu(true)
  }

  const handleRefresh = async() => {
    try {
      getUserInfo()
    } catch (error) {
      console.log("bir hata oluştu")
    }
  }
  return (
    <div className={styles.container}>
      {
        isMobile?
        <div className={styles.mobile} onClick={handleOpen}>
          <IoMdMenu />
        </div>
        :
        null
      }
      <div className={styles.userName}>
       {!isMobile && <FaUser />}
        <span style={{marginLeft:"5px"}}>{userInfo?.username}</span>
        </div>
      <div  className={isMobile ? styles.mobileBalanceContainer : styles.balanceContainer}>
        <div className={styles.balanceIcon}>
          <BiSolidCoinStack />
        </div>
        <div className={isMobile ? styles.balanceTextMobile : styles.balanceText}>{(userInfo.silverBalance || 0).toLocaleString("tr-TR")}</div>
        <div onClick={handleRefresh} className={styles.balanceUpdateIcon}>
          <GrUpdate />
        </div>
      </div>
    </div>
  );
};

export default Header;
