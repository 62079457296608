import React from "react";

import styles from "./Menu.module.css";
import { GoHomeFill } from "react-icons/go";
import { GiShop } from "react-icons/gi";
import { PiBoxArrowUpFill } from "react-icons/pi";
import { PiBoxArrowDownFill } from "react-icons/pi";
import { FaParachuteBox } from "react-icons/fa";
import { BsDoorClosedFill } from "react-icons/bs";
import { useAtom } from "jotai";
import { activeMenuAtom,sessionAtom } from "../../store/jotai";
import { useNavigate } from "react-router-dom";
import { MdContactSupport } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { GiSwordsEmblem } from "react-icons/gi";
import { IoSettingsSharp } from "react-icons/io5";
import { LuSwords } from "react-icons/lu";
import { AiOutlineMenuFold } from "react-icons/ai";


const menu = [
  { id: 1, name: "Home", link: "/dashboard", icon: <GoHomeFill /> },
  { id: 2, name: "Market", link: "/dashboard/market", icon: <GiShop /> },
  { id: 3, name: "Withdraw", link: "/dashboard/withdraw", icon: <PiBoxArrowUpFill /> },
  { id: 4, name: "Deposit", link: "/dashboard/deposit", icon: <PiBoxArrowDownFill /> },
  { id: 5, name: "Giveaway", link: "/dashboard/giveaway", icon: <FaParachuteBox /> },
  { id: 6, name: "Affiliate", link: "/dashboard/affiliate", icon: <FaUsers /> },
  { id: 7, name: "Guilds", link: "/dashboard/guilds", icon: <GiSwordsEmblem /> },
  { id: 8, name: "Builds", link: "/dashboard/builds", icon: <LuSwords /> },
  { id: 9, name: "Support", link: "/dashboard/support", icon: <MdContactSupport /> },
  { id: 10, name: "Settings", link: "/dashboard/settings", icon: <IoSettingsSharp /> },
];

const Menu = ({isMobile,setShowMenu}) => {
    const [activeMenu,setActiveMenu]=useAtom(activeMenuAtom)
    const navigate = useNavigate()
    const [session,setSession]=useAtom(sessionAtom)

    const handleClick = (id,link)=>{
        setActiveMenu(id)
        navigate(link)
        setShowMenu(false)
    }
    const handleExit = ()=>{
      setActiveMenu(1)
      localStorage.removeItem("session")
      navigate('/login')
    }

    const handleCloseMenu = ()=>{
      setShowMenu(false)
    }

  return (
    <div className={styles.container}>
      {
        isMobile ?
        <div className={styles.mobileTitleContainer} >
          <div>Albi<span style={{color:"#FF5F1F"}}>Trade</span></div>
          <div className={styles.MobileClsBtn} onClick={handleCloseMenu}><AiOutlineMenuFold/></div>
        </div>
        :
      <div className={styles.titleContainer}>Albi<span style={{color:"#FF5F1F"}}>Trade</span></div>
      }
      <div className={styles.menuContainer}>
        {menu.map((item, index) => {
          return (
            <div key={index} style={{color: activeMenu==item.id ? "#FF5F1F":null}} className={styles.menuItem} onClick={()=>handleClick(item.id,item.link)}>
              <div className={styles.menuItemIcon}>{item.icon}</div>
              <div className={styles.menuItemText}>{item.name}</div>
            </div>
          );
        })}
         <div  onClick={handleExit}  className={styles.menuItem}>
              <div className={styles.menuItemIcon}><BsDoorClosedFill /></div>
              <div className={styles.menuItemText}>Exit</div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
