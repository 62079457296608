import React from "react";

import styles from "./DHome.module.css";
import { useAtom } from "jotai";
import { userInfoAtom } from "../../../store/jotai";
import ResponsiveAdSenseBanner from "../../../components/Adsense/ResponsiveAdsense";

const DHome = ({ isMobile }) => {
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);

  return (
    <div
      style={{ flexDirection: isMobile ? "column" : null }}
      className={isMobile ? styles.mobileContainer :styles.container}
    >
      {/* Sol taraf */}
      <div className={isMobile ? styles.mobileLeftSide : styles.leftSide}>
        <div className={styles.pendingWithdrawsBox}>
        <h3>Pending Withdraws</h3>
          {
            userInfo?.waitingWithdraw?.map((item,index)=>{
             return (
             <div key={index}>
               <p>
                 withdraws pending from {item.islandName} - {item.chestname}. Last Date : {Intl.DateTimeFormat('en-EN', { 
                day: '2-digit', 
                month: 'long', // Ay ismi (tam)
                year: 'numeric', 
                hour:"2-digit",
                minute:"2-digit"
            }).format(new Date(item.lastDate))}
               </p>
            </div>)
            })}
        </div>
        <div className={styles.depositStatusBox}>
          <h3>Deposit Island Status</h3>
          <p>{userInfo.depositAccessIsland}</p>
        </div>
        <div className={styles.adbox}>
        </div>
      </div>

      {/* Sağ taraf */}
      <div className={isMobile ? styles.mobileRightSide : styles.rightSide}>
        <div className={styles.statBox}>
          <h4>User Name</h4>
          <p>{userInfo?.username}</p>
        </div>
        <div className={styles.statBox}>
          <h4>Balance</h4>
          <p>{userInfo?.silverBalance?.toLocaleString("tr-TR")}</p>
        </div>
        <div className={styles.statBox}>
          <h4>Pending Withdraws</h4>
          <p>{userInfo?.waitingWithdraw?.length}</p>
        </div>
        <div className={styles.statBox}>
          <h4>Affiliate Earnings</h4>
          <p>{userInfo?.affiliateEarnings?.toLocaleString("tr-TR")}</p>
        </div>
        <div className={styles.statBox}>
          <h4>Deposit Island</h4>
          <p>{userInfo?.depositAccessIsland}</p>
        </div>
      </div>
    </div>
  );
};

export default DHome;
