import React from 'react';
import styles from './HowCanIWithdraw.module.css';

const HowCanIWithdraw = () => {
  const items = [
    {
      title: "Go To Your Withdraw Island",
      description: "Go to the withdrawal island that the system tells you.",
      imageUrl: require("../../assets/withdrawimages/travel.webp"),
      imageAlt: "Başlık 1 Görsel",
    },
    {
      title: "Find Your Chest",
      description: "Find the house the system tells you and the chest inside. The chest locations are as follows.",
      imageUrl: require("../../assets/withdrawimages/map.webp"),
      imageAlt: "Başlık 2 Görsel",
    },
    {
      title: "",
      description: "",
      imageUrl: require("../../assets/withdrawimages/smallchest.webp"),
      imageAlt: "Başlık 2 Görsel",
    },
    {
      title: "",
      description: "",
      imageUrl: require("../../assets/withdrawimages/bigchests.webp"),
      imageAlt: "Başlık 2 Görsel",
    }
  ];

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>How Can I Withdraw</h1>
      {items.map((item, index) => (
        <div key={index} className={styles.item}>
          <h2 className={styles.itemTitle}>{item.title}</h2>
          <p className={styles.itemDescription}>{item.description}</p>
          <a href={item.imageUrl} target="_blank" rel="noopener noreferrer">
            <img
              src={item.imageUrl}
              alt={item.imageAlt}
              className={styles.itemImage}
            />
          </a>
        </div>
      ))}
    </div>
  );
};

export default HowCanIWithdraw;
