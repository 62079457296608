import React, { useState } from "react";
import styles from "./Deposit.module.css"; // Module CSS'yi import ediyoruz
import { useAtom } from "jotai";
import { userInfoAtom } from "../../../store/jotai";
import { Link } from "react-router-dom";

const DepositPage = () => {
  const [depositIsland, setDepositIsland] = useState(null); // "pending", "available" veya null olabilir
  const [userInfo,setUserInfo]=useAtom(userInfoAtom)

  const depositConditions = [
    { text: "Each item must be worth at least 100,000 silver.", type: "positive" },
    { text: "There is a cut of 5% to 25% for each item. This amount is determined by the market activity of the item (usually 10%)", type: "positive" },
    { text: "Only equipment and mounts are accepted. ( like armors, helmets, weapons, etc.)", type: "positive" },
    {
      text: "Some new equipment may not be accepted due to variable market prices.",
      type: "warning",
    },
    { text: "Scam equipment is not accepted.", type: "negative" },
    { text: "Resources and similar items are not accepted.", type: "negative" },
    { text: "Your unsupported items will not be accepted and will not be returned except in some cases.", type: "negative" },
  ];

  return (
    <div className={styles.depositPage}>
      <h1 className={styles.title}>Deposit</h1>

      <div className={styles.islandStatus}>
        {userInfo.depositAccessIsland === "pending" ? (
          <p className={styles.pendingText}>Pending</p>
        ) : userInfo.depositAccessIsland ? (
          <p className={styles.islandText}>Deposit Island: {userInfo?.depositAccessIsland}</p>
        ) : (
          <button className={styles.accessButton}>Request Access</button>
        )}
      </div>
      <Link  to="/howcanideposit" target="_blank" className={styles.chestButton}>
        <div>Where is Chest ?</div>
        </Link>
      <div className={styles.depositConditions}>
        <h2>How can i deposit ?</h2>
        <ul>
          <li className={styles.positiveItem}>
            After you get access to a deposit island, leave the items in the
            chest on the island. After the items are checked by the authorities,
            the balance will be loaded to your account. It may take up to 24
            hours for the balance to be loaded. Please deposit in accordance
            with the rules below.
          </li>
        </ul>
        <h2>Deposit Conditions</h2>
        <ul>
          {depositConditions.map((condition, index) => (
            <li
              key={index}
              className={
                condition.type === "positive"
                  ? styles.positiveItem
                  : condition.type === "negative"
                  ? styles.negativeItem
                  : styles.warningItem
              }
            >
              {condition.text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DepositPage;
