import React,{useState} from 'react'

import styles from "./RegisterPage.module.css"
import {Input,Select,Button,message} from 'antd'
import { UserOutlined,KeyOutlined,MailOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { sessionAtom } from '../../store/jotai'
import { useAtom } from 'jotai'
import { useMediaQuery } from 'react-responsive'

const RegisterPage = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' })

  const [email, setEmail] = useState("")
  const [password,setPassword] = useState("")
  const [server,setServer]=useState("eu")
  const [username,setUsername]=useState("")

  const [session,setSession]=useAtom(sessionAtom)
  const [messageApi, contextHolder] = message.useMessage();

  const showError = (message) => {
    messageApi.open({
      type: 'error',
      content: message,
    });
  };

  const handleRegister = async () => {
    // Validation kontrolü
    if (!username) {
      showError("Username cannot be empty.");
      return
    }
    
    if (!email) {
      showError("Email cannot be empty.");
      return
    }
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      showError("Invalid email address.");
      return
    }
    
    if (!password) {
      showError("Password cannot be empty.");
      return
    }
    
    if (password.length < 8) {
      showError("Password must be at least 8 characters long.");
      return
    }    
    if (username.length < 3) {
      showError("Username must be at least 3 characters long.");
      return
    }    

    try {
      const {data} = await axios.post('https://albitrade.com/api/auth/register', {
        username,
        email,
        password,
        server,
      });
  
      setSession(data?.token);
      // Kayıt başarılıysa yapılacak işlemler burada yer alır
    } catch (error) {
    console.log(error)
      if (error.response && error.response.data.error) {
        showError(error.response.data.error);
      } else {
        showError("An error has occurred!");
      }
    }
  };
  return (
    <div className={styles.container}>
      {contextHolder}
    <div className={isMobile ? styles.loginContainerMobile : styles.loginContainer}>
      <h1 className={styles.title}>Register</h1>
      <div className={styles.label}>Albion Online Username (For Trade)</div>
      <Input
      placeholder='Game Username'
      prefix={<UserOutlined />}
      className={styles.input}
      value={username}
      onChange={(e)=>{
        setUsername(e.target.value)
      }}
      />
      <div className={styles.label}>E-mail</div>
      <Input
      placeholder='E-mail'
      prefix={<MailOutlined />}
      className={styles.input}
      value={email}
      onChange={(e)=>{
        setEmail(e.target.value)
      }}
      />
      <div className={styles.label}>Password</div>
      <Input.Password
      placeholder='Password'
      prefix={<KeyOutlined />}
      className={styles.input}
      value={password}
      onChange={(e)=>{
        setPassword(e.target.value)
      }}
      />
      <div className={styles.label}>Game Server</div>
      <Select
      className={styles.input}
      defaultValue={"eu"}
      value={server}
      onChange={(e)=>{
        setServer(e.target.value)
      }}
       options={[
        {
          value: 'eu',
          label: 'eu',
        },
        {
          value: 'west',
          label: 'west',
          disabled:true
        },
        {
          value: 'east',
          label: 'east',
          disabled:true
        },
        ]}
      />
      <a  href="https://www.termsfeed.com/live/e50fec02-016d-4672-9c83-f501cd071190" target='_blank' className={styles.privatePolicty}>
        our private policy and  terms of use
      </a>
          <Button  type="primary" className={styles.button} onClick={handleRegister}>
          Register
        </Button>
        <div className={styles.registerContainer}>
          <Link className={styles.registerText} to={"/login"} >
           <div>Login</div>
          </Link>
        </div>
        </div>
        
    </div>
  )
}

export default RegisterPage
