"use client";
import { useEffect, useState } from "react";
import styles from "./BuildList.module.css";
import axios from "axios";

const BuildList = ({isMobile}) => {
  const [builds, setBuilds] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Arama terimi için state
  const [currentPage, setCurrentPage] = useState(1); // Mevcut sayfa
  const buildsPerPage = isMobile ? 5 : 10; // Her sayfadaki build sayısı
  const [isLoading, setIsLoading] = useState(true); // Resimlerin yüklenme durumu

  const getBuilds = async () => {
    try {
      const { data } = await axios.get("https://albitrade.com/api/builds/getallbuilds");
      setBuilds(data?.builds);
    } catch (error) {
      console.log("bir hata oluştu");
    }
  };

  useEffect(() => {
    getBuilds();
  }, []);

  useEffect(() => {
    setIsLoading(false); // Tüm veriler geldikten sonra yüklenme durumunu kaldır
  }, [builds]);

  // Arama filtresi
  const filteredBuilds = builds.filter(
    (build) => build.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sayfa değişimi işlemleri
  const indexOfLastBuild = currentPage * buildsPerPage;
  const indexOfFirstBuild = indexOfLastBuild - buildsPerPage;
  const currentBuilds = filteredBuilds.slice(indexOfFirstBuild, indexOfLastBuild);

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredBuilds.length / buildsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search by build name..."
        className={styles.searchInput}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <div className={isMobile ? styles.buildContainerMobile : styles.buildContainer}>
        {currentBuilds.map((build, index) => (
          <div key={index} className={styles.buildCard}>
            <div className={styles.imageGrid}>
              {build.items.map((item, idx) =>
                item !== "" ? (
                  <div key={idx} className={styles.imageWrapper}>
                    {isLoading ? (
                      <div className={styles.loadingBar}></div> // Loading bar
                    ) : (
                      <img
                        src={`https://render.albiononline.com/v1/item/${item}@0.png?quality=4`}
                        alt={`item-${idx}`}
                        className={styles.gridImage}
                        onLoad={() => setIsLoading(false)} // Resim yüklendiğinde loading biter
                      />
                    )}
                    <div className={styles.tooltip}>{item}</div>
                  </div>
                ) : (
                  <img
                    key={idx}
                    src={require("../../../assets/gapItem.png")}
                    alt={`item-${idx}`}
                    className={styles.gridImage}
                  />
                )
              )}
            </div>
            <div className={styles.buildInfo}>
              <h3 className={styles.buildName}>{build.name}</h3>
              <h3 className={styles.buildDate}>{build.creator}</h3>
              <p className={styles.buildDate}>
                {new Date(build.date).toLocaleDateString()}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Sayfa düğmeleri */}
      <div className={styles.pagination}>
        <button onClick={previousPage} disabled={currentPage === 1} className={styles.paginationButton}>
          Previous
        </button>
        <span className={styles.pageNumber}>{currentPage}</span>
        <button onClick={nextPage} disabled={currentPage === Math.ceil(filteredBuilds.length / buildsPerPage)} className={styles.paginationButton}>
          Next
        </button>
      </div>
    </div>
  );
};

export default BuildList;
