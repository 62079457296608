import React, { useEffect, useState } from 'react';
import styles from './Withdraw.module.css'; // Module CSS'yi import ediyoruz
import axios from 'axios';
import { Link } from 'react-router-dom';

const WithdrawsPage = () => {
  const [pendingItems,setPendingItems]=useState([])
  const [pendingWithdraws,setPendingWithdraws]=useState([])
  // Bekleme havuzu görselleri

const getPageData = async() => {
  try {
    const token = localStorage.getItem("session")
    const {data}=await axios.post("https://albitrade.com/api/pages/getuserwithdrawpage",{
      token
    })
    setPendingWithdraws(data?.waitingWithdraws)
    setPendingItems(data?.items)
  } catch (error) {
    console.log("bir hata oluştu")
  }
}
useEffect(()=>{
  getPageData()
},[])

return (
    <div className={styles.withdrawsPage}>
      <h1 className={styles.title}>Withdraws</h1>
      <h2>Pending Withdraws Pool</h2>
      <div className={styles.imageContainer}>
        {pendingItems.map((item, index) => (
          <div key={index} className={styles.imageWrapper}>
            <img src={item.image} alt={`Pool ${index + 1}`} className={styles.poolImage} />
          </div>
        ))}
      </div>
       

      <div className={styles.pendingWithdraws}>
        <h2>Active Withdraws</h2>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Island Name</th>
              <th>Chest Number</th>
              <th>Due Date</th>
            </tr>
          </thead>
          <tbody>
            {pendingWithdraws.map((withdraw, index) => (
              <tr key={index} className={styles.pendingRow}>
                <td>{withdraw.islandName}</td>
                <td>{withdraw.chestname}</td>
                <td> {
              Intl.DateTimeFormat('en-EN', { 
                day: '2-digit', 
                month: 'long', // Ay ismi (tam)
                year: 'numeric', 
                hour:"2-digit",
                minute:"2-digit"
            }).format(new Date(withdraw.lastDate))
          }</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Link  to="/howcaniwithdraw" target='_blank' className={styles.chestButton}>
        <div>Where is Chest ?</div>
        </Link>
    </div>
  );
};

export default WithdrawsPage;
