import React, { useEffect, useState } from 'react';

import styles from "./Giveaway.module.css"
import axios from 'axios';
import { useAtomValue } from 'jotai';
import { userInfoAtom } from '../../../store/jotai';

const GiveawayPage = ({getUserInfo}) => {
  const [giveaways,setGiveaways]=useState([])
  const [lastGiveaways,setLastGiveaways]=useState([])
  const userInfo = useAtomValue(userInfoAtom)
  const getGiveaways =async()=>{
    try {
      const {data}=await axios.get("https://albitrade.com/api/giveaways/getgiveaways")
      setGiveaways(data?.giveaways)
    } catch (error) {
      console.log("bir hata oluştu")
    }
  }

  const joinGiveaway = async(id)=>{
    try {
      const token = localStorage.getItem("session")
      const {data}=await axios.post("https://albitrade.com/api/giveaways/joingiveaways",{
        token,
        id
      })
      await getUserInfo()
      setGiveaways(data?.giveaways)
    } catch (error) {
      console.log("bir hata oluştu")
    }
  }
  const getLastGiveaways = async() => {
    try {
      const {data}=await axios.get("https://albitrade.com/api/giveaways/completed/last5")
      setLastGiveaways(data?.lastgiveaways)
    } catch (error) {
      console.log(error)
      console.log("bir hata oluştu")
    }
  }

  useEffect(()=>{
    getGiveaways()
    getLastGiveaways()
  },[])

  return (
    <div className={styles.giveawayPage}>
      <h1 className={styles.title}>Giveaways</h1>

      <div className={styles.giveawayList}>
        {giveaways?.map(item => (
          <div key={item._id} className={styles.giveawayCard}>
            <img src={item.imageurl} alt={item.name} className={styles.giveawayImage} />
            <h2 className={styles.giveawayName}>{item.name}</h2>
            <p className={styles.giveawayPrice}>{item.description}</p>
            <p className={styles.endDate}>winners: {item.numberofwinner}</p>
            <p className={styles.endDate}>participants: {item.joinedUsersCount}</p>
            <p className={styles.endDate}>Ends: {
              Intl.DateTimeFormat('en-EN', { 
                day: '2-digit', 
                month: 'long', // Ay ismi (tam)
                year: 'numeric', 
            }).format(new Date(item.endDate))
          }</p>
          {
            userInfo?.joinedGiveaways?.includes(item._id)  ? (
              
              <div className={styles.endDate} >Joined</div>
            )
            :
            (
              <button onClick={()=>joinGiveaway(item._id)} className={styles.joinButton}>Join Now</button>
            )
          }
          </div>
        ))}
      </div>
      <h1 className={styles.title}>Last Giveaways</h1>
      <div className={styles.giveawayList}>
      {lastGiveaways.map(item => (
          <div key={item._id} className={styles.giveawayCard}>
            <img src={item.imageurl} alt={item.name} className={styles.giveawayImage} />
            <h2 className={styles.giveawayName}>{item.name}</h2>
            <p className={styles.giveawayPrice}>{item.description}</p>
            <p className={styles.endDate}>winners:</p>
            {
              item.winners.map((name,index)=>{
                return <p key={index} className={styles.endDate}>{name}</p>
              })
            }
            <p className={styles.endDate}>Ends: {
              Intl.DateTimeFormat('en-EN', { 
                day: '2-digit', 
                month: 'long', // Ay ismi (tam)
                year: 'numeric', 
            }).format(new Date(item.endDate))
          }</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GiveawayPage;
