import React, { useEffect, useState } from "react";

import styles from "./Dashboard.module.css";
import { Routes, Route } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import Header from "../../components/Header/Header";
import { useMediaQuery } from 'react-responsive'
import { useAtom } from "jotai";
import { userInfoAtom } from '../../store/jotai';
import axios from 'axios';
import { message } from "antd";

import DHome from "./DHome/DHome";
import Market from "./Market/Market";
import Affiliate from "./Affiliate/Affiliate";
import Contact from "./Contact/Contact";
import GuildList from "./GuildList/GuildList";
import GiveawayPage from "./Giveaway/Giveaway";
import DepositPage from "./Deposit/Deposit";
import WithdrawsPage from "./Withdraw/Withdraw";
import SettingsPage from "./SettingsPage/SettingsPage";
import NotFoundPage from "./NotFound/NotFound";
import BuildList from "./BuildList/BuildList";

const Dashboard = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
  const [showMenu,setShowMenu]=useState(false)
  const [userInfo,setUserInfo]=useAtom(userInfoAtom)
  const [messageApi, contextHolder] = message.useMessage();

  const showMessage = (message,type) => {
    messageApi.open({
      type,
      content: message,
    });
  };

  const getUserInfo = async()=>{
    try {
      const sessionToken = localStorage.getItem("session")
      const {data} = await axios.post("https://albitrade.com/api/users/getUserInfo",{
        token:sessionToken
      })
      setUserInfo(data)
    } catch (error) {
      console.log("bir hata oluştu");
    }
  }

  useEffect(()=>{
    getUserInfo()
  },[])
  return (
    <div className={styles.container}>
      {contextHolder}
      {
        isMobile ? 
        <div style={{width:showMenu ?"100%" : "0%"}} className={styles.mobileMenuContainer}>
          <Menu setShowMenu={setShowMenu} isMobile={isMobile} />
        </div>
        :
      <div className={styles.menuContainer}>
        <Menu isMobile={isMobile} setShowMenu={setShowMenu}  />
      </div>
      }
      <div className={styles.rightContainer}>
        <div className={styles.header}>
          <Header isMobile={isMobile} setShowMenu={setShowMenu} getUserInfo={getUserInfo}/>
        </div>
        <div className={styles.pageContent}>
          <Routes>
            <Route path="/" element={<DHome  isMobile={isMobile} />}/>
            <Route path="market" element={<Market  isMobile={isMobile} showMessage={showMessage} getUserInfo={getUserInfo} />} />
            <Route path="affiliate" element={<Affiliate  isMobile={isMobile} showMessage={showMessage} getUserInfo={getUserInfo}/>} />
            <Route path="support" element={<Contact isMobile={isMobile}  />} />
            <Route path="guilds" element={<GuildList  isMobile={isMobile} />} />
            <Route path="builds" element={<BuildList  isMobile={isMobile} />} />
            <Route path="giveaway" element={<GiveawayPage  isMobile={isMobile} getUserInfo={getUserInfo}/>} />
            <Route path="deposit" element={<DepositPage  isMobile={isMobile} />} />
            <Route path="withdraw" element={<WithdrawsPage  isMobile={isMobile} />} />
            <Route path="settings" element={<SettingsPage isMobile={isMobile} showMessage={showMessage}/>} />
            <Route path="*" element={<NotFoundPage  isMobile={isMobile} />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
