import React, { useState } from "react";

import styles from "./LoginPage.module.css";
import { Input, Button, message } from "antd";
import { KeyOutlined, MailOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { sessionAtom } from "../../store/jotai";
import axios from "axios";
import { useMediaQuery } from 'react-responsive'

const LoginPage = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' })

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [session, setSession] = useAtom(sessionAtom);

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const showError = (message) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const handleLogin = async () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        showError("enter a valid email address");
        return;
      }
      if (password.length < 8) {
        showError("email or password is incorrect");
        return;
      }
      const { data } = await axios.post("https://albitrade.com/api/auth/login", {
        email,
        password,
      });
      setSession(data.token);
      navigate("/dashboard");
      
    } catch (error) {
      showError(error.response.data.error || "email or password is incorrect");
    }
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <div className={isMobile ? styles.loginContainerMobile : styles.loginContainer}>
        <h1 className={styles.title}>LOGIN</h1>
        <div className={styles.label}>E-mail</div>
        <Input
          placeholder="E-mail"
          prefix={<MailOutlined />}
          className={styles.input}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <div className={styles.label}>Password</div>
        <Input.Password
          placeholder="Password"
          prefix={<KeyOutlined />}
          className={styles.input}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <div className={styles.forgotContainer}>
          <Link to={"/forgotpassword"} className={styles.forgotText}>Forgot Password</Link>
        </div>
        <Button type="primary" className={styles.button} onClick={handleLogin}>
          Login
        </Button>
        <div className={styles.registerContainer}>
          <Link className={styles.registerText} to={"/register"}>
            <div>Register</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
