import React from 'react';

import styles from "./Contact.module.css"
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { FaDiscord } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive'


const Contact = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 700px)' })

  return (
    <div className={styles.contactPage}>
         <div className={styles.headerContainer}>
        <div className={isMobile ?styles.headerLeftMobile : styles.headerLeft}>
          <div className={isMobile ? styles.mobileTitle : styles.title}>
            <Link style={{textDecoration:"none",color:"white"}} to={"/"}>
            Albi<span style={{ color: "#FF5F1F" }}>Trade</span>
            </Link>
          </div>
        </div>
        <div className={isMobile ?styles.headerRightMobile : styles.headerRight}>
          <Link to={"/login"}>
            <Button className={isMobile ?styles.mobileRegisterBtn : styles.registerBtn}>Login</Button>
          </Link>
          <Link to={"/register"}>
            <Button className={isMobile ?styles.mobileRegisterBtn : styles.registerBtn}>Register</Button>
          </Link>
        </div>
      </div>
      <h1 className={styles.Pagetitle}>Contact Us</h1>

      <div className={styles.section}>
        <h3>Instant Support</h3>
        <p>
          For real-time support, join our Discord channel by clicking the link below:
        </p>
        <a 
          href="https://discord.com/invite/hvBmsd68Jh" 
          target="_blank" 
          rel="noopener noreferrer"
          className={styles.discordLink}
        >
         <div className={styles.iconContainer}><FaDiscord /></div> <div>Join Our Discord</div>
        </a>
      </div>

      <div className={styles.section}>
        <h3>Business Inquiries</h3>
        <p>
          For advertisement, sponsorship, suggestions, or complaints, feel free to reach out via email:
        </p>
        <div>
          albitrade.info@gmail.com 
        </div>
      </div>
    </div>
  );
};

export default Contact;
