import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import pages from "../pages";
import isTokenExpired from "../utils/jwtdecoder";
import { useAtom } from "jotai";
import { sessionAtom } from "../store/jotai";

const Router = () => {
  const [session, setSession] = useAtom(sessionAtom);
  useEffect(() => {
    if (session) {
      console.log(isTokenExpired(session));
      if (isTokenExpired(session)) {
        setSession("");
      }
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<pages.HomePage />} />
      <Route
        path="dashboard/*"
        element={
          <SessionAccess>
            <pages.Dashboard />
          </SessionAccess>
        }
      />
      
      <Route
        path="/login"
        element={
          <NonSessionAccess>
            <pages.LoginPage />
          </NonSessionAccess>
        }
      />
      <Route
        path="/register"
        element={
          <NonSessionAccess>
            <pages.RegisterPage />
          </NonSessionAccess>
        }
      />
      <Route
        path="/forgotpassword"
        element={
          <NonSessionAccess>
            <pages.ForgotPassword />
          </NonSessionAccess>
        }
      />
      <Route
        path="/resetPassword"
        element={
          <NonSessionAccess>
            <pages.ResetPassword />
          </NonSessionAccess>
        }
      />
      <Route path="/howcaniwithdraw" element={<pages.HowCanIWithdraw />} />
      <Route path="/howcanideposit" element={<pages.HowCanIDeposit />} />
      <Route path="/contact" element={<pages.Contact />} />
      <Route path="*" element={<pages.NotFoundPage />} />
    </Routes>
  );
};

const SessionAccess = ({ children }) => {
  const session = localStorage.getItem('session');

  return session ? children : <Navigate to="/login" />;
};

const NonSessionAccess = ({ children }) => {
  const session = localStorage.getItem('session');

  return !session ? children : <Navigate to="/dashboard" />;
};

export default Router;
