import React, { useState } from 'react';
import styles from './ForgetPassword.module.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // E-posta gönderme işlemi burada yapılacak
    axios.post("https://albitrade.com/api/auth/forgotpassword",{
      email
    })
    
    // E-posta gönderildiğinde submitted durumunu true yap
    setSubmitted(true);
  };

  return (
    <div className={styles.container}>
      {submitted ? (
        <div className={styles.successContainer}>

        <p className={styles.successMessage}>
          If your e-mail address is registered in the system, a password reset link will be sent to you.
        </p>
        <div>
          <Link className={styles.Homebutton} to={"/"}>
            Home
          </Link>
        </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className={styles.form}>
          <label htmlFor="email" className={styles.label}>Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
            placeholder="Your email..."
            required
          />
          <button type="submit" className={styles.button}>Send</button>
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
