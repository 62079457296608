import React, { useEffect, useState } from 'react';

import styles from "./Market.module.css"
import { BiSolidCoinStack } from "react-icons/bi";
import axios from 'axios';

const Market = ({isMobile,showMessage,getUserInfo}) => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = isMobile ? 10 : 36;

  const handlePageChange = (event) => {
    const pageNumber = parseInt(event.target.value, 10);
    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= Math.ceil(products.length / productsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  const getProducts = async() => {
    try {
      const {data} = await axios.get("https://albitrade.com/api/items/getAll");
      setProducts(data?.items);
    } catch (error) {
      console.log("bir hata oluştu");
    }
  };

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredProducts.length / productsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleBuy = async(product_id) => {
    try {
      const token = localStorage.getItem("session");
      const {data} = await axios.post("https://albitrade.com/api/withdraws/withdrawRequest", {
        itemId: product_id,
        token,
      });
      showMessage(data?.message, "success");
      setProducts(data?.items);
      getUserInfo();
    } catch (error) {
      console.log(error);
      showMessage(error.response.data.error, "error");
    }
  };

  // Ürünleri isme göre filtrele
  const filteredProducts = products.filter(product => 
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div className={styles.marketPage}>
      <h1 className={styles.title}>Market</h1>

      {/* Arama Kutusu */}
      <div className={styles.searchBar}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search item..."
          className={styles.searchInput}
        />
      </div>

      <div className={styles.productsGrid}>
        {currentProducts.map((product) => (
          <div className={styles.productCard} key={product._id}>
            <img src={product.image} alt={product.name} className={isMobile ? styles.productImageMobile : styles.productImage} />
            <h3 className={styles.productTitle}>{product.name}</h3>
            <p className={styles.productPrice}><BiSolidCoinStack/> {product.price.toLocaleString("tr-TR")}</p>
            <button onClick={() => {handleBuy(product._id)}} className={styles.buyButton}>Buy</button>
          </div>
        ))}
      </div>

      <div className={styles.pagination}>
        <button onClick={previousPage} className={styles.paginationButton} disabled={currentPage === 1}>
          Previous
        </button>
        <input
          type="number"
          value={currentPage}
          onChange={handlePageChange}
          className={styles.pageInput}
          min="1"
          max={Math.ceil(filteredProducts.length / productsPerPage)}
        />
        <button onClick={nextPage} className={styles.paginationButton} disabled={currentPage === Math.ceil(filteredProducts.length / productsPerPage)}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Market;
