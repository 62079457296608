import HomePage from "./HomePage";
import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";
import Dashboard from "./Dashboard";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import ForgotPassword from "./ForgetPassword/ForgetPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import Contact from "./Contact/Contact";
import HowCanIDeposit from "./HowCanIDeposit/HowCanIdeposit";
import HowCanIWithdraw from "./HowCanIWithdraw/HowCanIWithdraw";

const Pages = {
    HomePage,
    LoginPage,
    RegisterPage,
    Dashboard,
    NotFoundPage,
    ForgotPassword,
    ResetPassword,
    Contact,
    HowCanIDeposit,
    HowCanIWithdraw
};

export default Pages;