import React, { useEffect, useState } from 'react';
import { useSearchParams,useNavigate } from 'react-router-dom'; // Query parametre almak için
import styles from './ResetPassword.module.css';
import axios from 'axios';


const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); // Query'den token'i alıyoruz
  const navigate=useNavigate()
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

    const confirmToken = async()=>{
        try {
            const {data} =await axios.post("https://albitrade.com/api/auth/checkForgetToken",{
                token
            });
        } catch (error) {
            navigate("/")
        }
    }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords Not Match!');
      return;
    }
    try {
        await axios.post("https://albitrade.com/api/auth/resetForgotPassword",{
            token,
            newPassword:password
        })
        navigate("/login")
    } catch (error) {
        setError('Error');
    }
    // Token ve şifre gönderme işlemi burada yapılacak
    console.log("Şifre sıfırlanıyor...", { token, password });
  };

  useEffect(()=>{
    confirmToken()
  },[])

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h2 className={styles.title}>Reset Password</h2>
        
        <label htmlFor="password" className={styles.label}>New Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.input}
          placeholder="Your new password"
          required
        />
        
        <label htmlFor="confirmPassword" className={styles.label}>Confirm New Password:</label>
        <input
          type="password"
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className={styles.input}
          placeholder="Confirm  your new password"
          required
        />
        
        {error && <p className={styles.error}>{error}</p>}
        
        <button type="submit" className={styles.button}>Reset</button>
      </form>
    </div>
  );
};

export default ResetPassword;
